import Button from "@/components/Common/Buttons/Button/Button";
import AboutButton from "@/components/Common/Buttons/AboutButton/AboutButton";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import Pagination from "@/components/Components/Pagination/Pagination";
import MarketplaceFilter from "@/views/MarketplaceCategoryPage/MarketplaceFilter/MarketplaceFilter";
import ProductCategoriesGrid from "@/views/MarketplaceProductCategories/ProductCategoriesGrid/ProductCategoriesGrid";
import ProductCategoryTitle from "@/views/MarketplaceProductCategories/ProductCategoryTitle/ProductCategoryTitle";
import MarketplaceSortFilter from "@/views/MarketplaceCategoryPage/MarketplaceSortFilter/MarketplaceSortFilter";


export default {
    name: 'MarketplaceProductCategories',
    components: {

        Button,
        CheckBox,
        Pagination,
        MarketplaceFilter,
        ProductCategoriesGrid,
        MarketplaceSortFilter,
        ProductCategoryTitle,
        AboutButton
    },
    data() {
        return {
            categories: [],
            roomName: null,
        }
    },
    watch: {
        '$route.query': {
            handler: 'getProductCategories',
            deep: true,
            immediate: true
        }
    },
    methods: {
        async getProductCategories() {
            this.roomName = this.$route.query.room ?? null
            this.categories = await this.$store.dispatch('product/getProductCategories', this.roomName);
        }
    },
    async mounted() {
        this.getProductCategories();
    },
}
